export const IS_EMBED = window !== window.top;

module myUtil {
  export const formatNumber = (
    x: number | string,
    fractionDigits: number = 2,
    alwaysShowDecimalPoint: boolean = false
  ): string => {
    x = (+x).toFixed(fractionDigits);
    if (!alwaysShowDecimalPoint) x = parseFloat(x.toString());
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    var output = parts.join(".");
    // console.log(output);
    return output;
  };

  export const prepareFloatingNumber = (
    value: number,
    digit: number = 10
  ): number => {
    return parseFloat(value.toFixed(digit));
  };

  /**
   *
   * @param value floating values
   * @returns fixed floating values
   * Shortcut functions of prepareFloatingNumber
   */
  export const pfn = (value: number): number => prepareFloatingNumber(value);

  export const toggleDarkTheme = (shouldAdd: boolean) => {
    // console.log(shouldAdd);
    document.body.classList.toggle("dark", shouldAdd);
    localStorage.setItem("dark-mode-status", shouldAdd.toString());
  };

  export const setupDefaultDarkMode = () => {
    const savedDarkModeStatus = localStorage.getItem("dark-mode-status");
    if (savedDarkModeStatus !== null)
      toggleDarkTheme(savedDarkModeStatus === "true");
    else {
      const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
      toggleDarkTheme(prefersDark.matches);
      // Listen for changes to the prefers-color-scheme media query
      prefersDark.addListener((mediaQuery) =>
        toggleDarkTheme(mediaQuery.matches)
      );
    }
  };

  export const exportToCSV = (data: string[][], title?: string[]) => {
    var lineArray: string[] = [];
    data.forEach((infoArray, index) => {
      var line = infoArray.join(",");
      lineArray.push(
        index === 0 ? "data:text/csv;charset=utf-8," + line : line
      );
    });
    var csvContent = lineArray.join("\n");
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  export const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
  };
}

export default myUtil;
